import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import loadable from "@loadable/component";
import "../../styles/blocks/news.scss";
const Parser = loadable(() => import("../parser.js"));

const Query = (props) => {
  const { subQueries } = props;
  if (!props.attributes.variation) {
    return (<></>);
  }
  let posts = [];
  const currentPosts = subQueries?.find(e => e.id === props.attributes.queryId)?.posts?.nodes || [];
  if (props.attributes.variation === "one-last-news" || !props.allPosts) {
    currentPosts.length ? posts.push(...currentPosts) : posts.push(props.latestPost)
  } else {
    posts.push(...props.allPosts);
  }
  const content = props.structure;
  const propsCopy = {...props};
  delete propsCopy.structure;
  //~ delete propsCopy.allPosts;
  //~ delete propsCopy.latestPost;
  delete propsCopy.attributes;
  delete propsCopy.terms;
  delete propsCopy.children;
  delete propsCopy.title;
  const className = [
    "news",
    props.attributes.variation,
    props.attributes.displayLayout?.type,
    "columns-"+props.attributes.displayLayout?.columns
  ];
  if (props.attributes.align) {
    className.push("align"+props.attributes.align);
  }
  const Wrapper = ({ children }) => {
    if (props.attributes.variation === "one-last-news") {
      return <div style={ props.style } className="one-last-news">{ children }</div>;
    }
    return (<section style={ props.style }
      className={ className.join(" ") }
    >
      { children }
    </section>);
  };
  return (<Wrapper displayLayout={ props.attributes.displayLayout } >
    <Parser content={ content } {...propsCopy} oneLastNews={props.attributes.variation === "one-last-news"} posts={ posts } />
  </Wrapper>);
};

export default Query;